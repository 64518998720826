<template>
  <div class="auth-wrapper px-2">
    <div class="auth-inner py-2">
      <!-- <div class="auth-inner px-5 py-2">
    <div> -->
      <b-card class="mb-0">
        <div>
          <h4 class="mb-2">Stock Upload</h4>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-2 text-left">Order ID</div>
            <div class="col-10">
              <div class="d-flex justify-content-between align-items-center">
                <b-form-input
                  v-model="form_order_id"
                  placeholder="HE6281 , DB8240"
                  @keyup.enter="orderIDSearch(form_order_id)"
                />
                <!-- <a class="ml-1" @click="orderIDSearch"><h3>Go</h3></a> -->
                <b-button
                  class="ml-1"
                  variant="primary"
                  @click="orderIDSearch(form_order_id)"
                  :disabled="loading"
                >
                  <div v-if="!loading">
                    <feather-icon icon="SearchIcon" />
                    Search
                  </div>
                  <div v-if="loading">
                    <b-spinner small /> &nbsp; Loading...
                  </div>
                </b-button>
              </div>
            </div>
          </div>
          <b-alert variant="danger" show v-if="order_not_found">
            <div class="alert-body">
              <span>ไม่พบ Order</span>
            </div>
          </b-alert>

          <div class="row mb-1" v-if="select_display">
            <div class="col-2 text-left">เลือกอุปกรณ์</div>
            <div class="col">
              <v-select
                v-model="form_product_select"
                label="title"
                :options="products_option"
                :clearable="false"
              />
            </div>
          </div>
          <div class="row mb-1" v-if="select_display">
            <div class="col-2 text-left">รูปอัพโหลดแล้ว</div>
            <div class="col">{{ order_count }} รูป</div>
          </div>
          <!-- <div v-if="form_product_select"> -->
          <div v-if="ready_to_upload">
            <hr />
            <vue-dropzone
              id="dropzone"
              ref="imgDropzone"
              :options="dropzoneOption"
              @vdropzone-complete="afterComplete"
              class="mt-2"
            />
            <hr />
            <b-button variant="danger" @click="Clear">
              <feather-icon icon="XIcon" />Clear
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vSelect from "vue-select";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
let uuid = require("uuid");
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BAlert,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
    BSpinner,
  },
  created() {
    this.form_order_id = this.$route.query.order_id;
    if (!this.form_product_select) {
      this.form_product_select = this.products_option[0];
    }
  },
  data() {
    return {
      form_order_id: null,
      form_product_select: null,
      previewUrls: [],
      dropzoneOption: {
        url: "https://httpbin.org/post",

        addRemoveLinks: false,
        acceptedFiles: ".jpg,.jpeg,.png",
      },
      products_option: [
        // { title: "รวมทั้งหมด", type: "order" }, // Default option
      ],
      select_display: false,
      order_count: 0,
      order_not_found: false,
      loading: false,
      ready_to_upload: false,
    };
  },
  watch: {
    // Use a function to return the nested property you want to watch
    form_order_id: {
      handler(newVal, oldVal) {
        // As soon as form.order_id changes, set select_display to false
        this.select_display = false;
        this.ready_to_upload = false;
      },
      deep: true, // This is optional in this case as we're watching a direct property
    },
  },
  methods: {
    orderIDSearch(search_id) {
      try {
        if (search_id) {
          this.select_display = false;
          this.order_not_found = false;
          this.loading = true;
          this.$http({
            method: "GET",
            url: `/order/stock-image/` + search_id,
          })
            .then((x) => {
              // this.products_option = x.data.data.product;
              const res = x.data.data;

              this.products_option = [
                { title: "รวมทั้งหมด", type: "order", order_id: res.order_id },
                ...res.product,
              ];

              if (this.products_option.length > 0) {
                this.form_product_select = this.products_option[0];
              }

              this.order_count = res.image_count;
              this.loading = false;
              this.select_display = true;
              this.ready_to_upload = true;
            })
            .catch((e) => {
              this.ready_to_upload = false;
              this.order_not_found = true;
              this.select_display = false;
              this.loading = false;
            });
        }
      } catch {
        this.ready_to_upload = false;
        this.order_not_found = true;
        this.select_display = false;
        this.loading = false;
      }
    },
    async afterComplete(file) {
      try {
        const imageName = uuid.v1();
        var metaData = {
          contentType: "image/png",
        };
        const date = new Date();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Returns a number from 0 to 11
        const year = date.getFullYear().toString(); // Returns the full year (4 digits, e.g. 2021)

        const storage = getStorage();
        const storageRef = ref(
          storage,
          `stock_image_condition/${month}-${year}/${imageName}.png`
        );

        const uploadTask = uploadBytesResumable(storageRef, file, metaData);

        uploadTask.then(async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          const meta = await getMetadata(uploadTask.snapshot.ref);
          const x = this.form_product_select;

          const form = {
            product_id: x.product_id,
            product_sub_id: x.product_sub_id,
            img_path: meta.fullPath,
            img_url: url,
            type: x.type,
            booking_id: x.booking_id,
            order_id: x.order_id,
            product_serial: x.serial,
            product_no: x.number,
          };

          this.$http({
            method: "POST",
            url: `order/stock-image`,
            data: form,
          }).then((x) => {
            this.order_count = x.data.data + 1;
          });
        });
        this.$refs.imgDropzone.removeFile(file);
      } catch (error) {
        console.log(error);
      }
    },
    previewImage(event) {
      // get the input files
      const files = event.target.files;

      // loop through the files and generate a preview for each one
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // create an image object and get its URL
        const img = new Image();
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          img.src = reader.result;
          this.previewUrls.push(reader.result);
        });
        reader.readAsDataURL(file);
      }
    },
    Clear() {
      this.form = {};
      this.products_option = [];
      this.previewUrls = [];
      this.products_option = [];
      this.select_display = false;
      this.order_count = 0;
      this.loading = false;
      this.order_not_found = false;
      this.ready_to_upload = false;
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-auth.scss";
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 500px !important;
}
[dir] .vue-dropzone {
  border: 0px solid #e5e5e5;
}
[dir] .dropzone {
  border: 0px solid rgba(0, 0, 0, 0.3);
  background: #b1b1b1;
  padding: 20px 20px;
}
.vue-dropzone {
  font-family: inherit;
  letter-spacing: 0.2px;
  color: #000;
  transition: 0.2s linear;
}
.dropzone {
  min-height: 0px;
}
</style>
